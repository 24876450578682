import { Failure } from "./failure";

// Definición de la clase Either con dos subtipos: Left y Right.
export class Either<Left, Right> {
    private constructor(private readonly value: Left | Right) { }

    // Método estático para crear una instancia de Either con un valor Left.
    static left<Left, Right>(value: Left): Either<Left, Right> {
        return new Either<Left, Right>(value);
    }

    // Método estático para crear una instancia de Either con un valor Right.
    static right<Left, Right>(value: Right): Either<Left, Right> {
        return new Either<Left, Right>(value);
    }

    // Método para manejar el resultado con pattern matching.
    fold<T>(onLeft: (left: Left) => T, onRight: (right: Right) => T): T {
        return this.isLeft() ? onLeft(this.value as Left) : onRight(this.value as Right);
    }

    // Método para comprobar si el resultado es Left.
    isLeft(): boolean {
        var res = this.value !== undefined && this.value !== null && this.value instanceof Failure;
        return res
    }
}