import { Exception } from "./Exception";
export abstract class Failure {
  message: string;

  constructor({ message }: Failure) {
    this.message = message;
  }
}

export class ExceptionFailure extends Failure {
  exception: Exception;
  constructor({ message }: { message: string }) {
    super({ message });
  }
}

export class ErrorFailure extends Failure {
  error: Error;
  constructor({ message }: { message: string }) {
    super({ message });
  }
}
