import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";

import { HandleErrorService } from "./handle-error.service";
import { HeaderValidationService } from "./header-validation.service";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  constructor(
    private _httpClient: HttpClient,
    private _error: HandleErrorService,
    private _header: HeaderValidationService
  ) {}

  get<T>(
    url: string,
    sendToken: boolean = false,
    token: string = ""
  ): Observable<T> {
    return this._httpClient
      .get<T>(url, { headers: this._header.getHeaders(sendToken, token) })
      .pipe(catchError(this._error.handleError<T>));
  }

  post<T>(
    url: string,
    body: Object = {},
    sendToken: boolean = false,
    token: string = ""
  ): Observable<T> {
    return this._httpClient
      .post<T>(url, body, {
        headers: this._header.getHeaders(sendToken, token),
      })
      .pipe(catchError(this._error.handleError<T>));
  }

  put<T>(url: string, body: Object, sendToken: boolean = false): Observable<T> {
    return this._httpClient
      .put<T>(url, body, { headers: this._header.getHeaders(sendToken) })
      .pipe(catchError(this._error.handleError<T>));
  }

  delete<T>(url: string, sendToken: boolean = false): Observable<T> {
    return this._httpClient
      .delete<T>(url, { headers: this._header.getHeaders(sendToken) })
      .pipe(catchError(this._error.handleError<T>));
  }
}
