export interface     CreateUserDto {
  name?: string;
  lastName?: string;
  firebaseUid: string;
  fullNameOrCompanyName: string;
  email: string;
  phoneNumber: string;
  origin?: OriginType;
  userType: UserType;
  firebaseTokenDevice?: string;
}

export enum UserType {
  delegate = 'DELEGADO',
  representative = 'REPRESENTANTE',
  naturalPerson = 'PERSONA_NATURAL',
}

export enum OriginType {
  b2BWeb = 'B2B_WEB',
  b2CWeb = 'B2C_WEB',
}
