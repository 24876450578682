import {
  getAuth,
  createUserWithEmailAndPassword,
  UserCredential,
  Auth,
  fetchSignInMethodsForEmail,
} from "@angular/fire/auth";
import { Inject, Injectable } from "@angular/core";
import { Either } from "../helpers/either";
import { ErrorFailure, Failure } from "../helpers/failure";
import {
  FacebookAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";

@Injectable({
  providedIn: "root",
})
export class FirebaseAuthService {
  userCredential: UserCredential;
  constructor(@Inject(Auth) private auth: Auth) {}

  public async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<Either<Failure, UserCredential>> {
    try {
      let response = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return Either.right(response);
    } catch (error: any) {
      console.log(error);
      return Either.left(new ErrorFailure(error.toString()));
    }
  }

  public async emailExists(email: string): Promise<Either<Failure, boolean>> {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(this.auth, email);
      // If signInMethods is not null, it means the email exists
      return Either.right(signInMethods.length > 0);
    } catch (error: any) {
      // If an error is thrown, it means the email does not exist
      console.log(error);
      return Either.left(new ErrorFailure(error.toString()));
    }
  }

  public async loginFacebook() {
    try {
      return await signInWithPopup(this.auth, new FacebookAuthProvider());
    } catch (error) {
      console.error("Error during Facebook login:", error);
      throw error;
    }
  }

  setUserCredential(credential: UserCredential) {
    this.userCredential = credential;
  }

  getUserCredential() {
    return this.userCredential;
  }
}
