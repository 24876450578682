import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";
import { environment } from "@environments/environment";

@Component({
  selector: "app-metamap",
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: "./metamap.component.html",
  styleUrl: "./metamap.component.css",
})
export class MetamapComponent {
  @Output()
  public onVerificationCompleted = new EventEmitter<void>();

  @Output()
  public onVerificationCancelled = new EventEmitter<void>();

  metaData: string = "";
  flowId: string = environment.flowId;

  startMetamapVerification(userFirebaseUid: string) {
    this.metaData = JSON.stringify({"userId": userFirebaseUid})
    setTimeout(() => {
      const metamapButton = document.querySelector(
        ".hidden-button"
      ) as HTMLElement;
      if (metamapButton) {
        metamapButton.addEventListener("metamap:userFinishedSdk", (detail) => {
          console.log("Verification completed", detail);
          this.onVerificationCompleted.emit();
        });
  
        metamapButton.addEventListener("metamap:exitedSdk", (detail) => {
          console.log("Verification exited", detail);
          this.onVerificationCancelled.emit();
        });
  
        metamapButton.click();
      }
    }, 1000);
    
  }
}
