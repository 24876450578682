import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  public handleError<T>(err: any): Observable<T> {
    return throwError(() => err);
  }
}
