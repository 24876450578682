import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderValidationService {
  public getHeaders(sendAuthorization: boolean, token: string = ""): HttpHeaders {
    return !sendAuthorization && token != ""
      ? new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        })
      : new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
        });
  }

  private getAuthorization(): string {
    return localStorage.getItem('accessToken') ?? '';
  }
}
